<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Transferência entre Contas</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="card-body" v-if="!docsOk">
          <div class="row">
            <div class="col-md-12 text-center">
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                        <i class="fas fa-arrow-right"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-md">Antes de Transferir entre Contas, é necessário verificar a conta, completando o seu cadastro.</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-12 text-center">
              <router-link to="/pages/user" class="btn btn-primary">
                <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <div class="row">
            <div class="col-md-6" v-if="!showHistory">
              <div class="row">
                <div class="col-md-12">
                  <base-input label="Valor"
                              placeholder="0,00"
                              type="text"
                              name="Valor"
                              :rules="{max:14}"
                              v-money="money"
                              v-model="transfer.value">
                    <span slot="prepend">R$</span>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-md-10">
                  <label class="control-label float-right">
                    <a href="#" @click="loadHistory"><i slot="label" class="fas fa-list"></i> Últimos Destinos</a>
                  </label>
                  <base-input label="Usuário de Destino"
                              :rules="{required: true, min: 4, max: 50}">
                    <div class="form-group input-group">
                      <div class="input-group-prepend"><span class="input-group-text">@</span></div>
                      <input v-model="transfer.destination" aria-describedby="addon-right addon-left"
                             class="form-control"
                             name="Usuário de Destino">
                    </div>
                  </base-input>
                </div>
                <div class="col-md-2">
                  <base-input label=" ">
                    <button type="button" class="btn btn-primary" @click="search">
                      <span class="btn-label"><i class="fas fa-search"></i></span>
                    </button>
                  </base-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <card v-if="!isNullOrEmpty(transfer.destinationName)">
                    <div class="col-md-12 text-center" v-if="!isNullOrEmpty(transfer.destinationName)">
                      <label class="control-label">Nome Destino</label>
                      <p class="form-control-static">{{transfer.destinationName}}</p>
                    </div>
                    <div class="col-md-12" v-if="!isNullOrEmpty(transfer.destinationSocialNumber)"
                         style="text-align: center">
                      <label class="control-label">CPF/CNPJ</label>
                      <p class="form-control-static">{{transfer.destinationSocialNumber}}</p>
                      <input type="hidden" v-mask="['###.***.***-##', '##.###.###/####-##']"
                             v-model="transfer.destinationSocialNumber"/>
                    </div>
                    <div class="col-md-12" v-if="!isNullOrEmpty(transfer.email)" style="text-align: center">
                      <label class="control-label">E-mail Destinatário</label>
                      <p class="form-control-static">{{transfer.email}}</p>
                    </div>
                  </card>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <card v-if="!isNullOrEmpty(transfer.destinationName)">
                    <div class="col-md-12 text-center">
                      <label class="control-label">Descrição</label>
                      <input class="form-control" v-model="transfer.description" maxlength="20"/>
                      <button type="button" class="btn btn-primary m-3" v-bind:disabled="sendingTev"
                              @click="doTransaction">
                        <i slot="label" class="fas fa-exchange-alt"></i> Transferir
                      </button>
                    </div>
                  </card>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="!showHistory">
              <card
                header-classes="bg-transparent"
                body-classes="px-lg-7"
                class="card-pricing border-0 text-center mb-4">

                <!--Header-->
                <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Envie valores para outros
                  usuários Olimpo Pay!</h4>

                <!--Body-->
                <div class="display-2">R$ {{serviceFee}}</div>
                <span class=" text-muted">por transferência</span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Envie qualquer valor à outro usuário.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Confira atentamente os dados do usuário de destino.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Para sua organização, você pode adicionar uma breve descrição à transação.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Serviço isento de tarifa e sem limites de horários.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">A compensação dos valores é instantânea.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </card>
            </div>
            <div class="col-md-12" v-if="showHistory">
              <h4 slot="header" class="card-title">
                Selecione um Destino
              </h4>
              <div class="row clickable-rows">
                <div class="col-md-12 mt-2">
                  <el-table class="table-striped"
                            :data="tableData"
                            @row-click="selectDestination"
                            style="width: 100%; cursor: pointer"
                            empty-text="Nenhuma transação encontrada no período informado.">
                    <el-table-column :min-width="100" label="Usuário">
                      <template slot-scope="props">
                            <span>
                              <span><b>@{{props.row.destination}}</b></span>
                            </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="130" label="Nome">
                      <template slot-scope="props">
                            <span>
                              <span><b>{{props.row.destinationName}}</b></span>
                            </span>
                      </template>
                    </el-table-column>
                    <el-table-column :min-width="100" label="CPF/CNPJ">
                      <template slot-scope="props">
                            <span>
                              <span>{{props.row.destinationSocialNumber}}</span>
                            </span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="float-right mt-3 mb-2">
                    <base-pagination class="pull-right"
                                     v-model="pagination.currentPage"
                                     :per-page="pagination.perPage"
                                     :total="pagination.totalNumber"
                                     v-on:input="this.loadData">
                    </base-pagination>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button class="btn btn btn-primary m-3" @click="showHistory = false">
                    <i slot="label" class="fas fa-undo"></i> Voltar
                  </button>
                </div>
              </div>
            </div>
            <input type="hidden" id="authentication-copy" :value="authentication">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Option, Select, Table, TableColumn} from 'element-ui';
  import {BasePagination} from '@/components';
  import clientPaginationMixin from '../../util/tables/PaginatedTables/clientPaginationMixin'
  import DzsTevTransferDetails from "@/components/UIComponents/Dzs/Transfer/DzsTevTransferDetails"
  import {isNullOrEmpty, moneyToFloat, notNull, toMoney} from "@/util/core.utils"
  import {failWs, postWs} from "@/ws.service"
  import {VMoney} from 'v-money'

  export default {
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      DzsTevTransferDetails,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    data() {
      return {
        docsOk: false,
        serviceFee: '0,00',
        sendingTev: false,
        value: '0,00',
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        authentication: '',
        validateIntervalLoop: null,
        accountBalance: '0,00',
        transferToLegalPerson: false,
        transfer: {
          destination: '',
          destinationName: '',
          destinationSocialNumber: '',
          value: '',
          description: '',
          email: '',
        },
        showHistory: false,
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        propsToSearch: ['destination', 'destinationName', 'destinationSocialNumber'],
      };
    },
    created() {
      this.docsOk = localStorage.getItem("docsOk") == 'true'
      if (!this.docsOk) {
        return
      }
      this.accountBalance = notNull(localStorage.getItem('accountBalance'), '0,00')
    },
    methods: {
      copyAuthenticationCode() {
        let authenticationCopy = document.querySelector('#authentication-copy')
        authenticationCopy.setAttribute('type', 'text')
        authenticationCopy.select()

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          if (msg == 'successful') {
            this.$swal({
              text: 'Código do Comprovante copiado!',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              icon: 'success'
            })
          } else {
            this.$swal({
              text: 'Erro ao copiar o Código do Comprovante!',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          }
        } catch (err) {
          this.$swal({
            text: 'Erro ao copiar o Código do Comprovante!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        }

        /* unselect the range */
        authenticationCopy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
      },
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      search() {
        this.transfer.destination = this.transfer.destination.replace(' ', '').replace('@', '').toLowerCase()
        if (this.transfer.destination == '') {
          this.$swal({
            title: 'Aviso!',
            text: 'O campo Usuário de Destino é obrigatório!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          return
        }
        this.transfer.destinationName = ''
        this.transfer.destinationSocialNumber = ''
        postWs("/acc/find",
          true, {username: this.transfer.destination}, null,
          (response) => {
            this.transfer.destinationName = response.data.destinationName
            this.transfer.destinationSocialNumber = response.data.destinationSocialNumber
            this.transfer.email = response.data.email
            if (!isNullOrEmpty(response.data.destinationSocialNumber) && response.data.destinationSocialNumber.length > 11) {
              this.transferToLegalPerson = true
            } else {
              this.transferToLegalPerson = false
            }
          }, (error) => {
            this.transfer.destinationName = ''
            this.transfer.destinationSocialNumber = ''
            if (error.response.status == 404) {
              this.$swal({
                title: 'Aviso!',
                text: 'Conta destino não existe ou está bloqueada para receber transações.',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                icon: 'warning'
              })
            }
          })
      },
      doTransaction() {
        this.sendingTev = true
        if (moneyToFloat(this.transfer.value) > moneyToFloat(this.accountBalance)) {
          this.$swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          this.sendingTev = false
          return
        } else if (moneyToFloat(this.transfer.value) <= 0) {
          this.$swal({
            title: 'Aviso!',
            text: 'O valor tem que ser maior que ZERO!',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          this.sendingTev = false
          return
        }
        if (this.transferToLegalPerson) {
          this.$swal({
            text: "A Olimpo Pay é apenas uma facilitadora de pagamentos, regulamentada pela Lei 12.865/13. " +
              "Portanto, sempre confira a legalidade da negociação realizada. " +
              "Não nos responsabilizamos por negócios entre terceiros, nem mesmo por qualidade ou entrega de produto.",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-success btn-fill',
              cancelButton: 'btn btn-danger btn-fill'
            },
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'CONTINUAR',
            cancelButtonText: 'cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.transactionConfirmation()
            } else {
              this.sendingTev = false
            }
          })
        } else {
          this.transactionConfirmation()
        }
      },
      transactionConfirmation() {
        this.$swal({
          title: 'Confirma a transação ?',
          html: "USUÁRIO DE DESTINO: <b>@" + this.transfer.destination.toLowerCase() + "</b> <br/>" +
            "VALOR: <b>R$ " + this.transfer.value + "</b> <br/>",
          icon: 'info',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'SIM',
          cancelButtonText: 'cancelar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.callTransactionWS()
          } else {
            this.sendingTev = false
          }
        })
      },
      callTransactionWS() {
        this.transfer.description = this.transfer.description.toUpperCase()
        postWs("/transaction/tev",
          true, null,
          {
            destination: this.transfer.destination,
            value: moneyToFloat(this.transfer.value),
            description: this.transfer.description
          },
          (response) => {
            this.sendingTev = false
            if (response.data.response === 'OK') {
              this.success(response.data.authentication)
              return
            }
            let text = 'Falha ao transferir! Tente mais tarde.'
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          }, (error) => {
            this.sendingTev = false
            if (error == null) {
              return
            }

            let text = null;
            if (error.response.data.response == 'NO_MOBILE_DEVICE_REGISTERED') {
              text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
            } else if (error.response.data.response == 'NO_FUNDS') {
              text = 'Você não possui saldo suficiente! Altere o valor e tente novamente.'
            } else if (error.response.data.response == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
              text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
            } else if (error.response.data.response == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' || error.response.data.response == 'BLOCKED_OUT_TRANSACTIONS' ||
              error.response.data.response == 'BLOCKED') {
              text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
            } else if (error.response.data.response == 'OPERATION_EXCEEDS_LIMIT') {
              text = 'Esta transação excede os limites para sua conta. Entre em contato com nosso suporte para solicitar um aumento no seu limite.'
            } else if (error.response.data.response == 'SOURCE_USER_INACTIVE') {
              text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
            } else if (error.response.status === 412) {
              return;
            }
            if (!isNullOrEmpty(text)) {
              this.$swal({
                title: 'Aviso!',
                text: text,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-warning btn-fill'
                },
                icon: 'warning'
              })
            } else {
              this.$swal({
                title: 'Serviço temporariamente indisponível!',
                text: 'Tente novamente mais tarde.',
                timer: 5000,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger btn-fill'
                },
                icon: 'error'
              })
            }
          },
          true)
      },
      transferSuccess(response) {
        if (response.data.status == 'USED') {
          this.success(response.data.authentication)
        }
      },
      errorWs() {
        this.$swal({
          title: 'Aviso!',
          text: 'Código inválido!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-warning btn-fill'
          },
          icon: 'warning'
        })
        return
      },
      success(authentication) {
        this.authentication = authentication
        clearInterval(this.validateIntervalLoop)
        this.accountBalance = String(moneyToFloat(this.accountBalance) - moneyToFloat(this.transfer.value))
        localStorage.setItem('accountBalance', this.accountBalance)

        this.transfer.value = ''
        this.transfer.destination = ''
        this.transfer.destinationName = ''
        this.transfer.destinationSocialNumber = ''
        this.transfer.description = ''
        this.transfer.email = ''
        this.transferToLegalPerson = false
        this.$swal({
          title: 'Sucesso!',
          html: '<p>Código do Comprovante:<br>' + this.authentication + '</p>',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-info btn-fill',
            cancelButton: 'btn btn-success btn-fill'
          },
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Copiar Código',
          cancelButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.copyAuthenticationCode()
          }
        })
      },
      loadHistory() {
        this.showHistory = true
        postWs("/transaction/tev-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          },
          this.successLoadData,
          () => {
            this.tableData = []
          })
      },
      selectDestination(destination) {
        this.transfer.destination = destination.destination
        this.transfer.destinationName = destination.destinationName
        this.transfer.destinationSocialNumber = destination.destinationSocialNumber
        this.showHistory = false
      },
      loadData() {
        postWs("/transaction/tev-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      }
    },
    directives: {money: VMoney}
  };
</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
